import React from 'react';

const ResponseHistory = ({ responses, onEditResponses }) => {
  return (
    <div className="response-history">
      <h2>Historial de Respuestas</h2>
      {Object.entries(responses).map(([section, questions]) => (
        <div key={section}>
          <h3>{section}</h3>
          {Object.entries(questions).map(([question, answer]) => (
            <div key={question}>
              <p><strong>{question}</strong>: {answer.value}</p>
            </div>
          ))}
        </div>
      ))}
      <button 
        onClick={onEditResponses}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Editar Respuestas
      </button>
    </div>
  );
};

export default ResponseHistory;