import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import LoginForm from './components/Login/LoginForm';
import PasswordResetRequestForm from './components/Login/PasswordResetRequestForm';
import PasswordResetForm from './components/Login/PasswordResetForm';
import RegisterForm from './components/Register/RegisterForm';
import './assets/styles.css';
import Sidebar from './components/UI/sidebar';
import ResponseHistory from './components/ResponseHistory/ResponseHistory';
import LoadingSpinner from './components/UI/LoadingSpinner';
import ErrorMessage from './components/UI/ErrorMessage';
import Calendar from './components/Calendar/calendar';
import { gapi } from 'gapi-script';
import LinkedInComponent from './components/Linkedin/LinkedinInComponent';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, Users, Target, Star } from 'lucide-react';
import MarketingPlanGenerator from './components/marketingplangenerator';


const App = () => {
  const [sections, setSections] = useState({});
  const [mainInterests, setMainInterests] = useState([]);
  const [subInterests, setSubInterests] = useState({});
  const [responses, setResponses] = useState({});
  const [currentSection, setCurrentSection] = useState('');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [sessionId, setSessionId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [normalizedEvents, setNormalizedEvents] = useState([]);
  const [showIntro, setShowIntro] = useState(true);
  const [error, setError] = useState(null);
  const [rawMarketingPlan, setRawMarketingPlan] = useState(null);
  const [parsedPosts, setParsedPosts] = useState([]);
  const [selectedMainInterests, setSelectedMainInterests] = useState([]);
  const [selectedSubInterests, setSelectedSubInterests] = useState({});
  const [customAnswer, setCustomAnswer] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState('');
  const [showRegister, setShowRegister] = useState(false);
  const [savedResponses, setSavedResponses] = useState(null);
  const [username, setUsername] = useState('');
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [savedPlans, setSavedPlans] = useState([]);
  const [isGoogleApiReady, setIsGoogleApiReady] = useState(false);
  const [googleUser, setGoogleUser] = useState(null);
  const [showAuthOptions, setShowAuthOptions] = useState(false);
  const [generatingPlan, setGeneratingPlan] = useState(false);
  const [generationProgress, setGenerationProgress] = useState(0);
  const [marketingPlan, setMarketingPlan] = useState([]);
  const [showPasswordResetRequest, setShowPasswordResetRequest] = useState(false);
  const [showPasswordResetForm, setShowPasswordResetForm] = useState(false);
  const [passwordResetToken, setPasswordResetToken] = useState('');
// En App.js
  const [passwordResetEmail, setPasswordResetEmail] = useState('');

  const [showLogin, setShowLogin] = useState(false);
  const [isGeneratingPlan, setIsGeneratingPlan] = useState(false);



  useEffect(() => {
    if (isLoggedIn) {
      fetchSavedResponses();
    }
  }, [isLoggedIn]);



  const questionRefs = useRef({});
  const inputRefs = useRef({});
  const containerRef = useRef(null);

  const predefinedOptions = {
    "¿Cómo te definirías profesionalmente?": [
      "Soy un profesional dedicado al desarrollo de estrategias efectivas y sostenibles",
      "Me considero un experto en la optimización y mejora continua de procesos",
      "Soy un líder enfocado en la innovación y la resolución de problemas",
      "Me defino como un especialista en el diseño e implementación de soluciones estratégicas",
      "Soy un profesional comprometido con el crecimiento y la transformación organizacional",
      "Me considero un experto en la gestión y desarrollo de proyectos de alto impacto",
      "Soy un estratega enfocado en la creación de valor a largo plazo"
    ],
    "¿Cuánto tiempo piensas que podrías invertir en este proyecto?": [
      "Menos de 1 hora por semana",
      "Hasta 1 hora por semana",
      "Hasta 3 horas por semana",
      "Hasta 5 horas por semana",
      "Todas las horas que sean necesarias"
    ]
  };

  const handleError = useCallback((error, customMessage) => {
    console.error(customMessage, error);
    setError(customMessage);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const checkLoginStatus = async () => {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/verify-token`, {
            headers: { Authorization: `Bearer ${storedToken}` },
            timeout: 5000
          });
          if (response.data.valid) {
            setToken(storedToken);
            setIsLoggedIn(true);
            setUsername(localStorage.getItem('username'));
            await fetchSavedResponses(storedToken);
          } else {
            throw new Error('Token inválido');
          }
        } catch (error) {
          console.error('Error de autenticación:', error);
          handleLogout();
        }
      }
      setIsAuthChecked(true);
      setIsLoading(false);
    };

    checkLoginStatus();
  }, [handleError]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchSections();
    }
  }, [isLoggedIn, token]);

  useEffect(() => {
    const loadGoogleApi = async () => {
      const gapi = await import('gapi-script').then((pack) => pack.gapi);
      gapi.load('client:auth2', initClient);
    };

    loadGoogleApi();
  }, []);

  const initClient = () => {
    window.gapi.client.init({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
      scope: "https://www.googleapis.com/auth/calendar.events"
    }).then(() => {
      console.log("Google Calendar API inicializada correctamente");
      setIsGoogleApiReady(true);
    }).catch(error => {
      console.error("Error al inicializar Google Calendar API:", error);
    });
  };




  useEffect(() => {
    const fetchCalendarEvents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-marketing-plans`, {
          headers: { Authorization: `Bearer ${token}` }
        });
  
        const latestPlan = response.data.marketing_plans.at(-1); // Último plan
        if (latestPlan && latestPlan.plan) {
          setNormalizedEvents(latestPlan.plan.map(event => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end)
          })));
        } else {
          setNormalizedEvents([]); // Si no hay planes, limpiar el estado
        }
      } catch (error) {
        console.error('Error fetching calendar events:', error);
      }
    };
  
    fetchCalendarEvents();
  }, [token]); // Cambiar estado cuando el token se actualiza
  
  

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/verify-google-token`, {
        token: credential
      });
  
      if (response.data.valid) {
        setGoogleUser(response.data.user);
        localStorage.setItem('googleToken', credential);
        await initGoogleCalendarClient();
        await fetchGoogleCalendarEvents();
        setError(null);
        console.log('Inicio de sesión con Google exitoso');
      } else {
        throw new Error('Token de Google inválido');
      }
    } catch (error) {
      console.error('Error al verificar el token de Google:', error);
      setError('Error al iniciar sesión con Google. Por favor, inténtalo de nuevo.');
      setGoogleUser(null);
    }
  };
  


  const onEventChange = async (updatedEvents) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/update-marketing-plan`, {
        plan: updatedEvents,
        plan_index: savedPlans.length - 1
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Calendario guardado exitosamente');
    } catch (error) {
      console.error('Error al guardar el calendario:', error);
    }
  };
  
  const initGoogleCalendarClient = async () => {
    if (!window.gapi) {
      console.error('Google API client no está cargado');
      return;
    }
  
    try {
      await window.gapi.client.init({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
        scope: "https://www.googleapis.com/auth/calendar.events"
      });
  
      console.log('Google Calendar API inicializada correctamente');
      setIsGoogleApiReady(true);
    } catch (error) {
      console.error('Error al inicializar Google Calendar API:', error);
      setError('Error al inicializar Google Calendar. Por favor, recarga la página e inténtalo de nuevo.');
    }
  };
  
  const fetchGoogleCalendarEvents = async () => {
    if (!isGoogleApiReady) {
      console.error('Google Calendar API no está lista');
      setError('La API de Google Calendar no está inicializada. Por favor, recarga la página.');
      return;
    }
  
    if (!googleUser) {
      console.error('Usuario no autenticado en Google');
      setError('Por favor, inicia sesión con Google para acceder al calendario.');
      return;
    }
  
    setIsLoading(true);
  
    try {
      const response = await window.gapi.client.calendar.events.list({
        'calendarId': 'primary',
        'timeMin': (new Date()).toISOString(),
        'showDeleted': false,
        'singleEvents': true,
        'maxResults': 10,
        'orderBy': 'startTime'
      });
  
      const events = response.result.items;
      console.log('Eventos del calendario obtenidos:', events);
  
      const formattedEvents = events.map(event => ({
        id: event.id,
        title: event.summary,
        start: event.start.dateTime || event.start.date,
        end: event.end.dateTime || event.end.date,
        description: event.description,
        location: event.location,
        htmlLink: event.htmlLink,
      }));
  
      setCalendarEvents(formattedEvents);
  
      setError(null);
      console.log('Eventos del calendario actualizados en el estado');
    } catch (error) {
      console.error('Error al obtener eventos del calendario:', error);
      
      let errorMessage = 'Error al obtener eventos del calendario. ';
      
      if (error.result && error.result.error) {
        errorMessage += `${error.result.error.message} `;
      }
      
      errorMessage += 'Por favor, inténtalo de nuevo o verifica los permisos de tu cuenta.';
      
      setError(errorMessage);
      setCalendarEvents([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLoginFailure = () => {
    console.error('Google login failed');
  };


  const fetchSections = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/start-session`, {}, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 10000
      });
      
      setSections(response.data.sections);
      setMainInterests(response.data.main_interests);
      setSessionId(response.data.session_id);
      
      const firstSection = Object.keys(response.data.sections)[0];
      setCurrentSection(firstSection);
      
      initializeResponses(response.data.sections);
  
      // Limpiar el mensaje de error si la operación fue exitosa
      setError(null);
  
      setIsLoading(false);
    } catch (error) {
      handleError(error, 'Error al cargar las secciones. Por favor, intenta de nuevo.');
    }
  };
  
  const initializeResponses = (sections) => {
    const initialResponses = savedResponses || {};
    Object.keys(sections).forEach(section => {
      questionRefs.current[section] = sections[section].map(() => React.createRef());
      inputRefs.current[section] = sections[section].map(() => React.createRef());
      if (!initialResponses[section]) {
        initialResponses[section] = {};
        sections[section].forEach(question => {
          initialResponses[section][question] = { value: '' };
        });
      }
    });
    setResponses(initialResponses);
  };


  useEffect(() => {
    if (
      questionRefs.current[currentSection] &&
      questionRefs.current[currentSection][currentQuestionIndex] &&
      questionRefs.current[currentSection][currentQuestionIndex].current
    ) {
      questionRefs.current[currentSection][currentQuestionIndex].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [currentQuestionIndex, currentSection]);
  

  const handleLogin = async (usernameOrEmail, password) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, {
        username_or_email: usernameOrEmail,
        password
      });
      const { access_token } = response.data;
  
      setToken(access_token);
      setIsLoggedIn(true);
      setUsername(usernameOrEmail);
  
      const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // Expiración en 24 horas
      localStorage.setItem('token', access_token);
      localStorage.setItem('tokenExpiration', expirationTime.toString());
      localStorage.setItem('username', usernameOrEmail);
  
      // Limpiar el mensaje de error después de un inicio de sesión exitoso
      setError(null);
  
      // Llamar a una función para obtener las respuestas o datos guardados
      fetchSavedResponses(access_token);
    } catch (error) {
      handleError(error, 'Error de inicio de sesión. Verifica tus credenciales.');
    }
  };
  

  const handleRegister = async (username, email, password) => {
    try {
      // Realiza la solicitud y captura la respuesta
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/register`, {
        username,
        email,
        password,
      });
  
      // Verifica el código de estado (debería ser 201 para creación exitosa)
      if (response.status === 201) {
        // Opcional: muestra un mensaje de éxito al usuario
        alert(response.data.message || 'Registro exitoso. Ahora puedes iniciar sesión.');
  
        // Limpia cualquier mensaje de error previo
        setError('');
  
        // Actualiza el estado para mostrar el formulario de inicio de sesión
        setShowRegister(false);
        setShowLogin(true);
      } else {
        // Maneja casos donde el código de estado no es el esperado
        setError('Error en el registro. Inténtalo de nuevo.');
      }
    } catch (error) {
      // Maneja los errores y establece el mensaje de error adecuado
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Error en el registro. Inténtalo de nuevo.');
      }
    }
  };
  


  const handleLogout = () => {
    setToken('');
    setIsLoggedIn(false);
    setUsername('');
    setSavedResponses(null);
    setResponses({});
    setCurrentSection('');
    setCurrentQuestionIndex(0);
    setShowIntro(true);
    setIsAuthChecked(true);
    setIsLoading(false);
    setGoogleUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('username');
  };


  const fetchSavedResponses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-responses`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setResponses(response.data); // Eliminar la mezcla con hardcodedResponses
    } catch (error) {
      console.error('Failed to fetch saved responses:', error);
    }
  };
  



useEffect(() => {
  if (isLoggedIn) {
    fetchSavedResponses();
  }
}, [isLoggedIn]);




const saveResponses = async () => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/save-responses`, 
      { responses }, 
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log('Respuestas guardadas exitosamente:', responses);
  } catch (error) {
    console.error('Error al guardar las respuestas:', error);
    setError('Error al guardar las respuestas. Por favor, intenta de nuevo.');
  }
};
const handleAnswerChange = (question, value) => {
  setResponses(prevResponses => ({
    ...prevResponses,
    [currentSection]: {
      ...prevResponses[currentSection],
      [question]: { value }
    }
  }));
};





  const handleStartJourney = () => {
    setShowIntro(false);
    setCurrentSection('Entrevista');
    setCurrentQuestionIndex(0);
    
    // Guardar en localStorage que el usuario ya ha visto la introducción
    localStorage.setItem('hasSeenIntro', 'true');
  };
  



  const handleEditResponses = () => {
    setCurrentSection('Entrevista');
    setCurrentQuestionIndex(0);
    setShowHistory(false);
    setShowIntro(false);
  };


  const handleSavePlan = async () => {
    if (!rawMarketingPlan) {
      setError('No hay un plan de marketing para guardar.');
      return;
    }
  
    try {
      const planData = {
        plan: rawMarketingPlan,
        parsed_posts: parsedPosts,
      };
  
      await axios.post(`${process.env.REACT_APP_API_URL}/api/save-marketing-plan`, planData, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      alert('Plan de marketing guardado con éxito');
    } catch (error) {
      handleError(error, 'Error al guardar el plan de marketing. Inténtalo de nuevo.');
    }
  };
  
  const handleCustomAnswerChange = (e) => {
    setCustomAnswer(e.target.value);
  };

  const handleMainInterestChange = async (interest) => {
    setSelectedMainInterests(prev => {
      const newInterests = prev.includes(interest)
        ? prev.filter(i => i !== interest)
        : [...prev, interest].slice(0, 3);
      
      setSelectedSubInterests(prevSub => {
        const newSub = {...prevSub};
        if (!newInterests.includes(interest)) {
          delete newSub[interest];
        }
        return newSub;
      });

      return newInterests;
    });

    if (!subInterests[interest]) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get-sub-interests`, { main_interest: interest });
        setSubInterests(prev => ({ ...prev, [interest]: response.data.sub_interests }));
      } catch (error) {
        handleError(error, 'Error al cargar los sub-intereses. Por favor, intenta de nuevo.');
      }
    }
  };


  const handleForgotPassword = () => {
    setShowRegister(false);
    setShowPasswordResetRequest(true);
  };
  
  const onRequestReset = async (email) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/request-password-reset`, { email });
      alert(response.data.message);
      setShowPasswordResetRequest(false);
      // Establece el correo electrónico y muestra el formulario de restablecimiento
      setPasswordResetEmail(email);
      setShowPasswordResetForm(true);
    } catch (error) {
      console.error('Error al solicitar restablecimiento:', error);
      setError('Error al solicitar restablecimiento de contraseña. Por favor, intenta de nuevo.');
    }
  };
  
  
  const onResetPassword = async (email, code, newPassword) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/reset-password`, { email, code, new_password: newPassword });
      alert(response.data.message);
      setShowPasswordResetForm(false);
    } catch (error) {
      console.error('Error al restablecer contraseña:', error);
      setError('Error al restablecer la contraseña. Por favor, intenta de nuevo.');
    }
  };
  





  const handleSubInterestChange = (mainInterest, subInterest) => {
    setSelectedSubInterests(prev => ({
      ...prev,
      [mainInterest]: prev[mainInterest]
        ? prev[mainInterest].includes(subInterest)
          ? prev[mainInterest].filter(i => i !== subInterest)
          : [...prev[mainInterest], subInterest]
        : [subInterest]
    }));
  };

  const updateInterestsInBackend = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/update-interests`, {
        session_id: sessionId,
        main_interests: selectedMainInterests,
        sub_interests: selectedSubInterests
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
    } catch (error) {
      handleError(error, 'Error al actualizar los intereses. Por favor, intenta de nuevo.');
    }

  
  };

  const fetchMarketingPlan = async () => {
    setIsLoading(true);
    setError(null);
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/send-response`, {
            session_id: sessionId,
            responses
        }, {
            headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data.redirect_to_linkedin) {
            // Redirigir al usuario al flujo de autenticación de LinkedIn
            window.location.href = `${process.env.REACT_APP_API_URL}/api/linkedin/auth`;
            return; // Opcional, para evitar que el código siguiente se ejecute
        }

        if (response.data.estrategia_generada) {
            setRawMarketingPlan(response.data.contenido);
            setParsedPosts(response.data.parsed_posts || []);
        } else {
            throw new Error('No se pudo generar la estrategia de marketing.');
        }
    } catch (error) {
        handleError(error, 'Error al obtener el plan de marketing. Por favor, inténtalo de nuevo.');
    } finally {
        setIsLoading(false);
    }
};

  const handleNextSection = async () => {
    if (currentSection === "Intereses") {
      await updateInterestsInBackend();
    }

    const sectionKeys = Object.keys(sections);
    const currentSectionIndex = sectionKeys.indexOf(currentSection);
    if (currentSectionIndex < sectionKeys.length - 1) {
      setCurrentSection(sectionKeys[currentSectionIndex + 1]);
      setCurrentQuestionIndex(0);
    } else {
      await saveResponses();
      fetchMarketingPlan();
    }
  };

  





  const createCalendarEvents = (plan) => {
    const events = plan.map(post => ({
      id: post.id,
      title: post.title,
      start: new Date(post.date),
      end: new Date(new Date(post.date).getTime() + 60 * 60 * 1000), // 1 hora después
      extendedProps: {
        content: post.content,
        platform: post.platform,
      }
    }));
  
    setCalendarEvents(events);  // Actualiza los eventos del calendario
    setShowCalendar(true);
  };
  


const fetchSavedPlans = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-marketing-plans`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    setSavedPlans(response.data.marketing_plans);

    // Asumiendo que el último plan guardado es el más reciente
    if (response.data.marketing_plans.length > 0) {
      const latestPlan = response.data.marketing_plans[response.data.marketing_plans.length - 1];
      setCalendarEvents(latestPlan.plan);
    }
  } catch (error) {
    console.error('Error al obtener los planes de marketing guardados:', error);
  }
};


useEffect(() => {
  if (isLoggedIn) {
    fetchSavedResponses();
    fetchSavedPlans();
  }
}, [isLoggedIn]);
const handleSaveCalendar = async () => {
  try {
    // Asumiendo que estamos actualizando el último plan
    const planIndex = savedPlans.length - 1;
    await axios.post(`${process.env.REACT_APP_API_URL}/api/update-marketing-plan`, {
      plan: calendarEvents,
      plan_index: planIndex
    }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    alert('Calendario guardado exitosamente');
  } catch (error) {
    console.error('Error al guardar el calendario:', error);
  }
};


const parseSinglePost = (postContent) => {
  const lines = postContent.split('\n');
  const post = { type: 'unknown' };
  
  let isReadingContent = false;
  
  for (const line of lines) {
    if (line.startsWith('1. Fecha y hora')) {
      post.date = line.split(': ')[1].trim();
    } else if (line.startsWith('2. Plataforma')) {
      const platform = line.split(':')[1].trim().toLowerCase();
      post.type = platform.includes('linkedin') ? 'linkedin' : 
                  platform.includes('instagram') ? 'instagram' : 'unknown';
      post.platform = platform;
    } else if (line.startsWith('3. El texto completo')) {
      isReadingContent = true;
      post.content = '';
    } else if (line.startsWith('4. Describe con detalle')) {
      isReadingContent = false;
      post.mediaDescription = line.split(':').slice(1).join(':').trim();
    } else if (line.startsWith('5. Qué quieres lograr')) {
      post.objective = line.split(':').slice(1).join(':').trim();
    } else if (isReadingContent) {
      post.content += line + '\n';
    }
  }
  
  // Limpiar el contenido y extraer título y hashtags
  if (post.content) {
    const contentLines = post.content.trim().split('\n');
    post.title = contentLines[0].trim();
    post.content = contentLines.slice(1).join('\n').trim();
    
    // Extraer hashtags
    const hashtags = post.content.match(/#\w+/g);
    post.hashtags = hashtags ? hashtags.join(' ') : '';
    
    // Eliminar la sección de "Imágenes o videos sugeridos:" si existe
    const mediaIndex = post.content.indexOf('Imágenes o videos sugeridos:');
    if (mediaIndex !== -1) {
      post.content = post.content.substring(0, mediaIndex).trim();
    }
  }
  
  return post;
};





  const handlePreviousSection = () => {
    const sectionKeys = Object.keys(sections);
    const currentSectionIndex = sectionKeys.indexOf(currentSection);
    if (currentSectionIndex > 0) {
      setCurrentSection(sectionKeys[currentSectionIndex - 1]);
      setCurrentQuestionIndex(sections[sectionKeys[currentSectionIndex - 1]].length - 1);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (currentQuestionIndex < sections[currentSection].length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        handleNextSection();
      }
    }
  };

 
  const handleGenerateNewPlan = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/generate-plan`, { responses }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setRawMarketingPlan(response.data.contenido);
      setParsedPosts(response.data.parsed_posts || []);
    } catch (error) {
      handleError(error, 'Error al generar el nuevo plan. Inténtalo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const handleShowHistory = () => {
    setShowHistory(true);
    setShowCalendar(false);
  };
  
 const handleCreateCalendar = () => {
    if ((!parsedPosts || parsedPosts.length === 0) && (!savedPlans || savedPlans.length === 0)) {
      setError('No posts or saved plans available to create the calendar. Please generate a marketing plan first.');
      return;
    }
    
    let events = [];
    
    if (parsedPosts && parsedPosts.length > 0) {
      events = parsedPosts.map(post => ({
        title: post.title,
        start: new Date(post.start),
        content: post.content,
        platform: post.platform
      }));
    } else if (savedPlans && savedPlans.length > 0) {
      const lastPlan = savedPlans[savedPlans.length - 1];
      events = parsePostsFromPlan(lastPlan.plan);
    }
  
    setCalendarEvents(events);
    setShowCalendar(true);
  };

  
  const parsePostsFromPlan = (plan, desiredFrequency) => {
    if (!plan || typeof plan !== 'string') {
      console.error('Invalid plan provided to parsePostsFromPlan');
      return [];
    }
  
    const posts = plan.split('--- POST INICIO ---')
      .filter(post => post.trim() !== '')
      .map(post => {
        const [header, body] = post.split('--- POST CUERPO ---');
        
        let date = '', platform = '', title = '', content = '';
  
        if (header) {
          const dateMatch = header.match(/Fecha y hora: (\d{2}\/\d{2}\/\d{4} \d{2}:\d{2})/);
          const platformMatch = header.match(/Plataforma: (.+)/);
          date = dateMatch ? dateMatch[1] : '';
          platform = platformMatch ? platformMatch[1].trim() : '';
        }
  
        if (body) {
          const titleMatch = body.match(/Título: (.+)/);
          title = titleMatch ? titleMatch[1].trim() : '';
          content = body.trim();
        }
  
        return { date, platform, title, content };
      });
    
    const adjustedPosts = posts.map((post, index) => ({
      title: post.title || 'Untitled',
      start: post.date ? new Date(post.date.split(' ')[0].split('/').reverse().join('-')) : new Date(Date.now() + index * 24 * 60 * 60 * 1000),
      content: post.content,
      platform: post.platform
    }));
  
    return adjustDatesBasedOnFrequency(adjustedPosts, desiredFrequency);
  };
  
  const adjustDatesBasedOnFrequency = (posts, frequency) => {
    let startDate = new Date();
    return posts.map((post, index) => {
      switch(frequency) {
        case 'daily':
          post.start = new Date(startDate.getTime() + index * 24 * 60 * 60 * 1000);
          break;
        case 'weekly':
          post.start = new Date(startDate.getTime() + index * 7 * 24 * 60 * 60 * 1000);
          break;
        default:
          // Si la frecuencia no es reconocida, mantener la fecha original
          break;
      }
      return post;
    });
  };
  
  const handleEventUpdate = async (updatedEvent) => {
    if (!isGoogleApiReady || !googleUser) {
      console.error("Google API no está lista o el usuario no está autenticado");
      return;
    }
  
    try {
      const response = await window.gapi.client.calendar.events.update({
        calendarId: 'primary',
        eventId: updatedEvent.id,
        resource: {
          summary: updatedEvent.title,
          description: updatedEvent.extendedProps.content,
          start: { dateTime: updatedEvent.start.toISOString() },
          end: { dateTime: updatedEvent.end.toISOString() }
        }
      });
  
      if (response.status !== 200) {
        throw new Error(`Failed to update event. Status: ${response.status}`);
      }
  
      setCalendarEvents(prevEvents =>
        prevEvents.map(event =>
          event.id === updatedEvent.id ? updatedEvent : event
        )
      );
  
      setSelectedEvent(null);
      console.log('Event updated successfully');
    } catch (error) {
      console.error('Error updating event in Google Calendar:', error);
      handleError(error, 'Error al actualizar el evento en Google Calendar.');
    }
  };

  const handleEditCalendar = () => {
    setShowCalendar(true);
    setShowHistory(false);
    setShowIntro(false);
  };
  
  const handleEventSelect = (event) => {
    setSelectedEvent(event);
  };


  


  const handleShowPlanCreation = (section) => {
    setShowHistory(false);
    setShowIntro(false);
    setShowCalendar(true);
    setCurrentSection(section);
    
    const sectionMapping = {
      'introduccion': 'Entrevista',
      'intereses': 'Intereses',
      'testPersonalidad': 'Test de Personalidad',
      'ultimosPosteos': 'Posteos Anteriores',
      'crearCalendario': 'Crear Calendario',
      'verEditarCalendario': 'Ver/Editar Calendario'
    };
  
    const targetSection = sectionMapping[section];
  
    if (targetSection) {
      setCurrentSection(targetSection);
      setCurrentQuestionIndex(0);
  
      if (rawMarketingPlan) {
        setRawMarketingPlan(null);
        setParsedPosts([]);
      }
  
      if (targetSection === 'Crear Calendario' || targetSection === 'Ver/Editar Calendario') {
        setShowAuthOptions(true);
        setShowCalendar(true);
        handleCreateCalendar();
      } else {
        setShowAuthOptions(false);
      }
  
      switch (section) {
        case 'intereses':
          if (mainInterests.length === 0) {
            fetchInterests();
          }
          break;
        case 'crearCalendario':
        case 'verEditarCalendario':
          const questionIndex = sections['Preferencias de Marketing'].findIndex(q => 
            q.includes('duración') || q.includes('frecuencia')
          );
          setCurrentQuestionIndex(questionIndex !== -1 ? questionIndex : 0);
          break;
        default:
          break;
      }
    } else {
      console.error('Sección no reconocida:', section);
    }
  };
  
  const fetchInterests = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-interests`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setMainInterests(response.data.main_interests);
      setSubInterests(response.data.sub_interests);
    } catch (error) {
      handleError(error, 'Error al cargar los intereses. Por favor, intenta de nuevo.');
    }
  };


  const renderInputFields = (question, index) => {
    if (!currentSection || !sections[currentSection]) return null;
  
    const isPredefinedQuestion = predefinedOptions[question];
    const currentAnswer = responses[currentSection]?.[question]?.value || '';
  
    if (isPredefinedQuestion) {
      return (
        <div className="options-container">
          {predefinedOptions[question].map((option, optionIndex) => (
            <button
              key={optionIndex}
              className={`option-button ${responses[currentSection] && responses[currentSection][question]?.value === option ? 'selected' : ''}`}
              onClick={() => handleAnswerChange(question, option)}
            >
              {option}
            </button>
          ))}
          <div>
            <label>
              <input 
                type="radio" 
                checked={currentAnswer === 'custom'} 
                onChange={() => handleAnswerChange(question, 'custom')}
              /> Escribe tu propia respuesta
            </label>
            {currentAnswer === 'custom' && (
              <input
                type="text"
                className="input-field custom-option"
                value={customAnswer}
                onChange={handleCustomAnswerChange}
                placeholder="Escribe tu respuesta"
              />
            )}
          </div>
        </div>
      );
    } else if (currentSection === "Test de Personalidad") {
      const options = [
        { letter: 'A', text: 'Muy de acuerdo' },
        { letter: 'B', text: 'De Acuerdo' },
        { letter: 'C', text: 'Algo de Acuerdo' },
        { letter: 'D', text: 'Neutro' },
        { letter: 'E', text: 'No del todo de acuerdo' },
        { letter: 'F', text: 'No de acuerdo' },
        { letter: 'G', text: 'No para nada de acuerdo' }
      ];
  
      return (
        <div className="options-container">
          {options.map((option, optionIndex) => (
            <button
              key={optionIndex}
              className={`option-button ${currentAnswer === option.text ? 'selected' : ''}`}
              onClick={() => handleAnswerChange(question, option.text)}
            >
              <span className="option-letter">{option.letter}</span>
              {option.text}
            </button>
          ))}
        </div>
      );
    } else if (currentSection === "Intereses") {
      return (
        <div className="interests-selection">
          <h3 className="text-xl font-semibold mb-2">Selecciona hasta 3 intereses principales</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
            {mainInterests.map((interest) => (
              <div key={interest} className="flex items-center">
                <input
                  type="checkbox"
                  id={interest}
                  checked={selectedMainInterests.includes(interest)}
                  onChange={() => handleMainInterestChange(interest)}
                  className="mr-2"
                />
                <label htmlFor={interest}>{interest}</label>
              </div>
            ))}
          </div>
          {selectedMainInterests.map((mainInterest) => (
            <div key={mainInterest} className="mb-6">
              <h4 className="text-lg font-semibold mb-2">{mainInterest}</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {subInterests[mainInterest]?.map((subInterest) => (
                  <div key={subInterest} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`${mainInterest}-${subInterest}`}
                      checked={selectedSubInterests[mainInterest]?.includes(subInterest) || false}
                      onChange={() => handleSubInterestChange(mainInterest, subInterest)}
                      className="mr-2"
                    />
                    <label htmlFor={`${mainInterest}-${subInterest}`}>{subInterest}</label>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      );
    } else if (currentSection === "Preferencias de Marketing") {
      switch (question) {
        case "¿De qué temas principales quieres hablar en tus publicaciones?":
          return (
            <textarea
              className="input-field"
              value={currentAnswer}
              onChange={(e) => handleAnswerChange(question, e.target.value)}
              placeholder="Describe los temas principales de tus publicaciones"
              ref={inputRefs.current[currentSection]?.[index]}
            />
          );
          case "¿Qué duración quieres para tu plan de marketing? (semanal/mensual/trimestral)":
            return (
              <select
                className="input-field"
                value={currentAnswer}
                onChange={(e) => handleAnswerChange(question, e.target.value)}
                ref={inputRefs.current[currentSection]?.[index]}
              >
                <option value="">Selecciona una opción</option>
                <option value="semanal">Semanal</option>
                <option value="mensual">Mensual</option>
                <option value="trimestral">Trimestral</option>
              </select>
            );
        case "¿Con qué frecuencia quieres publicar? (diario/cada 2 días/semanal/etc.)":
            const options = ['diario', 'cada 2 días', 'cada 3 días', 'semanal', 'cada 2 semanas', 'mensual'];
            
            return (
              <div>
                <select
                  className="input-field"
                  value={currentAnswer}
                  onChange={(e) => handleAnswerChange(question, e.target.value)}
                  ref={inputRefs.current[currentSection]?.[index]}
                >
                  <option value="">Selecciona una opción</option>
                  {options.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
            );
        default:
          return null;
      }
    } else {
      return (
        <input
          type="text"
          className="input-field"
          value={currentAnswer}
          onChange={(e) => handleAnswerChange(question, e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder={`Ingresa ${question.toLowerCase()}`}
          disabled={index !== currentQuestionIndex}
          ref={inputRefs.current[currentSection]?.[index]}
        />
      );
    }
  };


  
  const renderParsedPosts = () => {
    if (!Array.isArray(parsedPosts) || parsedPosts.length === 0) {

    }
  
    return parsedPosts.map((post, index) => (
      <div key={index} className="bg-gray-800 rounded-lg p-6 mb-6 shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <span className="text-sm text-gray-400">{post.date}</span>
          <span className="text-sm text-blue-500">{post.platform}</span>
        </div>
        <div className="mb-4">
          <h3 className="text-xl font-semibold text-white">{post.title}</h3>
          <p className="text-gray-300 mt-2">{post.content}</p>
          {post.hashtags && (
            <p className="text-sm text-gray-400 mt-2">{post.hashtags}</p>
          )}
        </div>
        <div className="border-t border-gray-700 pt-4">
          <h4 className="text-lg text-white font-semibold mb-2">Detalles de la publicación</h4>
          <ul className="text-gray-400 space-y-1">
            <li><strong>Tipo:</strong> {post.type}</li>
            <li><strong>Objetivo:</strong> {post.objective}</li>
            <li><strong>Descripción de imagen/video:</strong> {post.mediaDescription}</li>
          </ul>
        </div>
      </div>
    ));
  };
  


  useEffect(() => {
    const hasSeenIntro = localStorage.getItem('hasSeenIntro');
  
    // Si el usuario ya ha visto la introducción, no mostrarla
    if (hasSeenIntro) {
      setShowIntro(false);
    }
  }, []);
  
  const renderRawMarketingPlan = () => {
    if (!rawMarketingPlan) {
      return <p className="text-center text-gray-500">No se ha generado ningún plan de marketing aún.</p>;
    }
  
    return (
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
        <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto">{rawMarketingPlan}</pre>
        <button 
          onClick={handleSavePlan} 
          className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
        >
          Guardar Plan de Marketing
        </button>
      </div>
    );
  };
  const renderMainContent = () => {
    if (showHistory) {
      return (
        <ResponseHistory
          responses={responses}
          savedPlans={savedPlans}
          onEditResponses={handleEditResponses}
        />
      );
    }
  
    if (showCalendar) {
      return (
        <>
          {showAuthOptions && (
            <div className="auth-options flex flex-col items-center space-y-4 mb-6">
              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <GoogleLogin
                  onSuccess={handleGoogleLoginSuccess}
                  onFailure={handleGoogleLoginFailure}
                  className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
                />
              </GoogleOAuthProvider>
              <LinkedInComponent />
            </div>
          )}
          <Calendar
            events={calendarEvents}
            onEventSelect={handleEventSelect}
            onEventUpdate={handleEventUpdate}
            selectedEvent={selectedEvent}
            isGoogleApiReady={isGoogleApiReady}
            isSignedIn={isLoggedIn}
          />
        </>
      );
    }
  
    if (rawMarketingPlan) {
      return (
        <div className="space-y-6">
          <h2 className="text-3xl font-bold text-white">Plan de Marketing</h2>
          {renderParsedPosts()}
          <h3 className="text-2xl font-semibold text-white mt-8">Plan de Marketing Completo:</h3>
          {renderRawMarketingPlan()}
          <button 
            onClick={handleCreateCalendar} 
            className="mt-4 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
          >
            Crear Calendario
          </button>
        </div>
      );
    }

  
    // Si no hay plan generado, mostrar el cuestionario
    return (
      <div className="questionnaire-container">
        <h2>{currentSection}</h2>
        <TransitionGroup>
          <LinkedInComponent />
          {sections[currentSection]?.map((question, index) => (
            <CSSTransition key={index} timeout={500} classNames="fade">
              <div 
                ref={questionRefs.current[currentSection]?.[index]}
                className={`question ${index === currentQuestionIndex ? 'active' : 'inactive'}`}
              >
                <div className="question-number">{index + 1}.</div>
                <div className="question-text">{question}</div>
                {renderInputFields(question, index)}
                {index === currentQuestionIndex && (
                  <div className="navigation-buttons">
                    {currentQuestionIndex > 0 && (
                      <button 
                        onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
                        className="nav-button"
                      >
                        Anterior
                      </button>
                    )}
                    <button 
                      onClick={currentQuestionIndex < sections[currentSection].length - 1 ? () => setCurrentQuestionIndex(currentQuestionIndex + 1) : handleNextSection}
                      className="nav-button"
                    >
                      {currentQuestionIndex < sections[currentSection].length - 1 ? 'Siguiente' : 'Finalizar Sección'}
                    </button>
                  </div>
                )}
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
        {currentSection === Object.keys(sections)[Object.keys(sections).length - 1] && 
         currentQuestionIndex === sections[currentSection]?.length - 1 && (
          <button onClick={fetchMarketingPlan} className="submit-button">
            Generar Plan de Marketing
          </button>
        )}
      </div>
    );
  };
  if (!isAuthChecked) {
    return <LoadingSpinner message="Verificando autenticación..." />;
  }
  
  if (generatingPlan) {
  return (
    <div>
      <button onClick={() => setGeneratingPlan(true)}></button>
      <MarketingPlanGenerator generatingPlan={generatingPlan} />
    </div>
  );
} else if (isLoading) {
  return (
    <div>
      <button onClick={() => setIsLoading(true)}></button>
      <MarketingPlanGenerator generatingPlan={isLoading} />
    </div>
  );
}

  





  // Dentro del return del componente App
if (!isLoggedIn) {
  return (
    <div className="auth-container">
      {showRegister ? (
        <RegisterForm
          onRegister={handleRegister}
          onSwitch={() => setShowRegister(false)}
          error={error}
          successMessage={<span style={{ color: 'white' }}>Registro exitoso. Por favor, inicia sesión.</span>}
        />
      ) : showPasswordResetRequest ? (
        <PasswordResetRequestForm
          onRequestReset={onRequestReset}
          onSwitch={() => setShowRegister(false)}
          error={error}
        />
      ) : showPasswordResetForm ? (
        <PasswordResetForm
        onResetPassword={onResetPassword}
        email={passwordResetEmail}
        error={error}
        />
      ) : (
        <LoginForm
          onLogin={handleLogin}
          onSwitch={() => setShowRegister(true)}
          onForgotPassword={handleForgotPassword} // <-- Añadir esta línea
          error={error}
        />
      )}
    </div>
  );
}


  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="app-container bg-gradient-to-br from-gray-900 to-black text-white font-roboto min-h-screen flex" ref={containerRef}>
        <AnimatePresence>
          {isLoggedIn && (
            <motion.div
              initial={{ x: -250 }}
              animate={{ x: 0 }}
              exit={{ x: -250 }}
              transition={{ duration: 0.3 }}
            >
             <Sidebar 
  user={username}
  onShowHistory={handleShowHistory}
  onShowPlanCreation={handleShowPlanCreation}
  isHistoryActive={showHistory}
  onLogout={handleLogout}
  currentSection={currentSection}
/>

            </motion.div>
          )}
        </AnimatePresence>
        
        <motion.div 
          className="main-content flex-grow p-8 overflow-y-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <AnimatePresence>
            {isLoggedIn ? (
              showIntro ? (
                <motion.div 
                  className="intro-container text-center max-w-4xl mx-auto"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">
                    Bienvenido a PersonalMARKA
                  </h1>
                  <p className="text-2xl mb-8 text-gray-300 font-light">
                    Descubrí tu verdadero valor y alcanzá otras posibilidades a través de tu marca personal
                  </p>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                    <FeatureCard 
                      icon={<Users className="w-12 h-12 text-blue-400" />}
                      title="Equipo Experto"
                      description="Nuestro equipo de marketing y comunicación digital te brinda estrategias personalizadas."
                    />
                    <FeatureCard 
                      icon={<Target className="w-12 h-12 text-purple-400" />}
                      title="Enfoque Personalizado"
                      description="Nos enfocamos en ti para ayudarte a destacar y lograr nuevos objetivos."
                    />
                    <FeatureCard 
                      icon={<Star className="w-12 h-12 text-yellow-400" />}
                      title="Diferenciación"
                      description="Te proporcionamos las herramientas necesarias para diferenciarte en tu industria."
                    />
                  </div>
                  <div className="bg-gray-800 p-6 rounded-lg mb-12">
                    <h2 className="text-3xl font-semibold mb-4">¿Qué es la marca personal?</h2>
                    <p className="text-xl text-gray-300">
                      Es lo que una persona cuenta al mundo, destacando no solo lo que hace, sino cómo y por qué lo hace.
                    </p>
                  </div>
                  <motion.button 
                    onClick={handleStartJourney} 
                    className="start-button bg-gradient-to-r from-blue-500 to-purple-600 text-white text-xl font-bold py-4 px-10 rounded-full shadow-lg hover:shadow-xl transition duration-300 ease-in-out flex items-center justify-center mx-auto"
                    whileHover={{ scale: 1.05, boxShadow: "0 0 15px rgba(74, 144, 226, 0.5)" }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Comenzar tu viaje
                    <ChevronRight className="ml-2" size={24} />
                  </motion.button>
                </motion.div>
              ) : (
                renderMainContent()
              )
            ) : (
              <div className="auth-container">
                {showRegister ? (
                  <RegisterForm onRegister={handleRegister} onSwitch={() => setShowRegister(false)} error={error} />
                ) : (
                  <LoginForm onLogin={handleLogin} onSwitch={() => setShowRegister(true)} error={error} />
                )}
              </div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </GoogleOAuthProvider>
  );
};
const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-gray-800 p-6 rounded-lg shadow-md">
    <div className="flex justify-center mb-4">
      {icon}
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-400">{description}</p>
  </div>
);

export default App;