import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Linkedin.css'; // Asegúrate de crear este archivo CSS

const LinkedInComponent = ({ onLogin, onLogout, isLoggedIn, publishContent }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [internalLoggedIn, setInternalLoggedIn] = useState(false);

  useEffect(() => {
    checkLoginStatus();
    handleAuthCallback();
  }, []);

  const apiBaseUrl = process.env.REACT_APP_LINKEDIN_API_URL;

  const checkLoginStatus = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/linkedin/login-status`);
      if (response.data.isLoggedIn) {
        handleLoginSuccess();
      }
    } catch (err) {
      console.error('Error checking login status:', err);
    }
  };

  const handleAuthCallback = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const authStatus = urlParams.get('auth');
    if (authStatus === 'success') {
      handleLoginSuccess();
      // Clear the URL parameters after handling them
      window.history.replaceState({}, document.title, window.location.pathname);
    } else if (authStatus === 'error') {
      const errorMessage = urlParams.get('message');
      setError(decodeURIComponent(errorMessage || 'Error de autenticación'));
      // Clear the URL parameters after handling them
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  };

  const handleLoginSuccess = () => {
    setInternalLoggedIn(true);
    if (typeof onLogin === 'function') {
      onLogin();
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    setError(null);
    try {
      const redirectUrl = window.location.origin + window.location.pathname;
      const response = await axios.get(`${apiBaseUrl}/api/linkedin/auth-url?redirect_url=${encodeURIComponent(redirectUrl)}`);
      window.location.href = response.data.authUrl;
    } catch (err) {
      setError('Error al iniciar sesión con LinkedIn');
      console.error('Error de inicio de sesión:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${apiBaseUrl}/api/linkedin/logout`);
      setInternalLoggedIn(false);
      if (typeof onLogout === 'function') {
        onLogout();
      }
    } catch (err) {
      console.error('Error logging out:', err);
    }
  };

  const handlePublish = async (content) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${apiBaseUrl}/api/linkedin/publish`,
        { content },
        { withCredentials: true }
      );

      if (response.status === 200) {
        console.log('Contenido publicado en LinkedIn:', response.data);
        if (typeof publishContent === 'function') {
          publishContent(content, 'linkedin');
        }
      } else {
        throw new Error('Error al publicar en LinkedIn');
      }
    } catch (err) {
      setError('Error al publicar en LinkedIn');
      console.error('Error de publicación:', err);
    } finally {
      setLoading(false);
    }
  };

  const isActuallyLoggedIn = isLoggedIn ?? internalLoggedIn;

  return (
    <div className="linkedin-component">
      <div className="linkedin-button-container">
        {!isActuallyLoggedIn ? (
          <button 
            className="linkedin-button linkedin-login-button" 
            onClick={handleLogin} 
            disabled={loading}
          >
            {loading ? (
              <>
                <span className="loading-spinner"></span>
                Iniciando sesión...
              </>
            ) : (
              <>
                <i className="fab fa-linkedin"></i> Iniciar sesión con LinkedIn
              </>
            )}
          </button>
        ) : (
          <button 
            className="linkedin-button linkedin-logout-button" 
            onClick={handleLogout}
          >
            <i className="fas fa-sign-out-alt"></i> Cerrar sesión de LinkedIn
          </button>
        )}
      </div>
      {error && <p className="linkedin-error-message">{error}</p>}
    </div>
  );
};

export default LinkedInComponent;