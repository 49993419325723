import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  HomeIcon,
  SparklesIcon,
  UserCircleIcon,
  CalendarIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

const Sidebar = ({
  user,
  onLogout,
  onShowHistory,
  onShowPlanCreation,
  isHistoryActive,
  currentSection,
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const marketingPlanSections = [
    {
      name: 'introduccion',
      label: 'Introducción',
      internalName: 'Entrevista',
      icon: <HomeIcon className="w-6 h-6" />,
    },
    {
      name: 'intereses',
      label: 'Intereses',
      internalName: 'Intereses',
      icon: <SparklesIcon className="w-6 h-6" />,
    },
    {
      name: 'testPersonalidad',
      label: 'Test de Personalidad',
      internalName: 'Test de Personalidad',
      icon: <UserCircleIcon className="w-6 h-6" />,
    },
    {
      name: 'ultimosPosteos',
      label: 'Últimos Posteos',
      internalName: 'Posteos Anteriores',
      icon: <ClipboardDocumentListIcon className="w-6 h-6" />,
    },
    {
      name: 'preferenciasMarketing',
      label: 'Preferencias de Marketing',
      internalName: 'Preferencias de Marketing',
      icon: <ClockIcon className="w-6 h-6" />,
    },
  ];

  const sidebarClasses = `
  bg-gray-900 text-white w-64 h-screen flex flex-col shadow-lg
  fixed md:sticky top-0 left-0 z-50 transition-transform duration-300 ease-in-out
  ${isSmallScreen ? (isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full') : 'translate-x-0'}
  overflow-hidden
`;

return (
    <>
      {/* Botón de menú móvil */}
      {isSmallScreen && (
        <div className="fixed top-0 left-0 right-0 bg-gray-900 text-white flex items-center justify-between p-4 z-40">
          <h2 className="text-xl font-bold">Bienvenido, {user}</h2>
          <button onClick={toggleMobileMenu} className="focus:outline-none">
            {isMobileMenuOpen ? (
              <XMarkIcon className="w-8 h-8" />
            ) : (
              <Bars3Icon className="w-8 h-8" />
            )}
          </button>
        </div>
      )}

      {/* Overlay para cerrar el menú en móviles */}
      {isSmallScreen && isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={closeMobileMenu}
        ></div>
      )}

      {/* Sidebar */}

    <aside className={sidebarClasses}>
        <div className="flex flex-col h-full overflow-y-auto">
          <div className="sidebar-header p-6 bg-gray-800 hidden md:block">
            <h2 className="text-2xl font-bold">Bienvenido, {user}</h2>
          </div>
          <nav className="sidebar-nav flex-grow p-4">
            <ul>
              {marketingPlanSections.map((section, index) => (
                <li key={index} className="mb-2">
                  <motion.button
                    onClick={() => {
                      onShowPlanCreation(section.name);
                      closeMobileMenu();
                    }}
                    className={`w-full flex items-center text-left px-4 py-2 rounded hover:bg-gray-700 transition-colors duration-200 ${
                      currentSection === section.internalName ? 'bg-gray-700' : ''
                    }`}
                    whileHover={{ scale: 1.02 }}
                  >
                    {section.icon}
                    <span className="ml-3">{section.label}</span>
                  </motion.button>
                </li>
              ))}
            <li className="mb-2">
              <motion.button
                onClick={() => {
                  onShowHistory();
                  closeMobileMenu();
                }}
                className={`w-full flex items-center text-left px-4 py-2 rounded hover:bg-gray-700 transition-colors duration-200 ${
                  isHistoryActive ? 'bg-gray-700' : ''
                }`}
                whileHover={{ scale: 1.02 }}
              >
                <ClipboardDocumentListIcon className="w-6 h-6" />
                <span className="ml-3">Historial</span>
              </motion.button>
            </li>
            <li className="mb-2">
              <motion.button
                onClick={() => {
                  onShowPlanCreation('verEditarCalendario');
                  closeMobileMenu();
                }}
                className="w-full flex items-center text-left px-4 py-2 rounded hover:bg-gray-700 transition-colors duration-200"
                whileHover={{ scale: 1.02 }}
              >
                <CalendarIcon className="w-6 h-6" />
                <span className="ml-3">Ver Calendario</span>
              </motion.button>
            </li>
            <li className="mb-2">
              <motion.button
                onClick={() => {
                  onShowPlanCreation('crearCalendario');
                  closeMobileMenu();
                }}
                className="w-full flex items-center text-left px-4 py-2 rounded hover:bg-gray-700 transition-colors duration-200"
                whileHover={{ scale: 1.02 }}
              >
                <CalendarIcon className="w-6 h-6" />
                <span className="ml-3">Crear Calendario</span>
              </motion.button>
            </li>
            </ul>
          </nav>
          <div className="sidebar-footer p-4 mt-auto">
            <motion.button
              onClick={() => {
                onLogout();
                closeMobileMenu();
              }}
              className="w-full flex items-center text-left px-4 py-2 rounded hover:bg-red-600 transition-colors duration-200"
              whileHover={{ scale: 1.02 }}
            >
              <ArrowLeftOnRectangleIcon className="w-6 h-6" />
              <span className="ml-3">Cerrar Sesión</span>
            </motion.button>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;