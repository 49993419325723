import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { format } from 'date-fns';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const Calendar = ({ events, savedPlans, token }) => {
  const [normalizedEvents, setNormalizedEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedEventContent, setSelectedEventContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNewEventDialogOpen, setIsNewEventDialogOpen] = useState(false);
  const [newEventDate, setNewEventDate] = useState(null);
  const [newEvent, setNewEvent] = useState({
    title: '',
    content: '',
    time: new Date(),
  });

  useEffect(() => {
    if (events && events.length > 0) {
      const normalizedEvents = events.map(event => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
        title: event.title || 'Sin título',
        extendedProps: {
          content: event.content || '',
          platform: event.platform || '',
          hashtags: event.hashtags || '',
        },
      }));
      setNormalizedEvents(normalizedEvents);
    } else {
      setNormalizedEvents([]);
    }
  }, [events]);

  const handleEventClick = (clickInfo) => {
    const event = clickInfo.event;
    const isSelected = selectedEvents.some(e => e.id === event.id);
  
    if (isSelected) {
      setSelectedEvents(selectedEvents.filter(e => e.id !== event.id));
      setSelectedEventContent(null);
    } else {
      setSelectedEvents([...selectedEvents, event]);
      setSelectedEventContent(event.extendedProps.content || '');
    }
  };
  
  const handleSelectAllEvents = () => {
    setSelectedEvents(normalizedEvents);
  };

  const handleDeselectAllEvents = () => {
    setSelectedEvents([]);
    setSelectedEventContent(null);
  };

  const handleEventDrop = async (eventDropInfo) => {
    const updatedEvents = normalizedEvents.map(event => {
      if (event.id === eventDropInfo.event.id) {
        return {
          ...event,
          start: eventDropInfo.event.start,
          end: eventDropInfo.event.end
        };
      }
      return event;
    });
    setNormalizedEvents(updatedEvents);
    await onEventChange(updatedEvents);
  };
  
  const handleEventResize = async (eventResizeInfo) => {
    const updatedEvents = normalizedEvents.map(event => {
      if (event.id === eventResizeInfo.event.id) {
        return {
          ...event,
          start: eventResizeInfo.event.start,
          end: eventResizeInfo.event.end
        };
      }
      return event;
    });
    setNormalizedEvents(updatedEvents);
    await onEventChange(updatedEvents);
  };

  const onEventChange = async (updatedEvents) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/update-marketing-plan`, {
        plan: updatedEvents,
        plan_index: savedPlans.length - 1
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Calendario guardado exitosamente');
    } catch (error) {
      console.error('Error al guardar el calendario:', error);
    }
  };

  const handleSaveCalendar = async () => {
    try {
      if (!savedPlans || savedPlans.length === 0) {
        alert('No hay planes guardados para actualizar.');
        return;
      }
      const planIndex = savedPlans.length - 1;
      await axios.post(`${process.env.REACT_APP_API_URL}/api/update-marketing-plan`, {
        plan: normalizedEvents,
        plan_index: planIndex
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('Calendario guardado exitosamente');
    } catch (error) {
      console.error('Error al guardar el calendario:', error);
    }
  };
  
  const handleBatchLinkedInPublish = async () => {
    if (selectedEvents.length === 0) {
      setError('No hay eventos seleccionados para publicar');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('linkedinToken');
      const postsToSchedule = selectedEvents.map(event => ({
        content: event.extendedProps.content,
        scheduledAt: event.start.toISOString()
      }));

      const response = await axios.post(
        `${process.env.REACT_APP_LINKEDIN_API_URL}/api/linkedin/batch-schedule`,
        { posts: postsToSchedule },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.status === 200) {
        const updatedEvents = normalizedEvents.map(event => {
          if (selectedEvents.some(e => e.id === event.id)) {
            return {
              ...event,
              extendedProps: {
                ...event.extendedProps,
                linkedInScheduled: true
              }
            };
          }
          return event;
        });

        setNormalizedEvents(updatedEvents);
        setSelectedEvents([]);
        setSelectedEventContent(null);
        alert(`${response.data.scheduled_post_ids.length} publicaciones programadas en LinkedIn exitosamente`);
      } else {
        setError(`Error al programar en LinkedIn: ${response.data.error || 'Error desconocido'}`);
      }
    } catch (error) {
      console.error('Error scheduling LinkedIn posts:', error);
      setError(`Error al programar en LinkedIn: ${error.response?.data?.error || error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    const isSelected = selectedEvents.some(e => e.id === event.id);
  
    return (
      <div className={`p-1 rounded ${isSelected ? 'bg-indigo-200' : 'bg-white'} hover:bg-indigo-100 transition-colors duration-200 shadow-sm overflow-hidden`}>
        <div className="text-xs font-semibold mb-1 truncate">{event.title}</div>
        <div className="text-xxs text-gray-600 truncate">{event.extendedProps?.content || 'Sin contenido'}</div>
      </div>
    );
  };
  

  const handleDateClick = (arg) => {
    setNewEventDate(arg.date);
    setIsNewEventDialogOpen(true);
  };

  const handleNewEventChange = (field, value) => {
    setNewEvent(prev => ({ ...prev, [field]: value }));
  };

  const handleNewEventSubmit = () => {
    const eventDateTime = new Date(newEventDate);
    eventDateTime.setHours(newEvent.time.getHours(), newEvent.time.getMinutes());

    const newEventObject = {
      id: Date.now().toString(),
      title: newEvent.title,
      start: eventDateTime.toISOString(),
      end: eventDateTime.toISOString(),
      extendedProps: {
        content: newEvent.content,
      },
    };

    setNormalizedEvents(prev => [...prev, newEventObject]);
    setIsNewEventDialogOpen(false);
    setNewEvent({ title: '', content: '', time: new Date() });
  };

  return (
    <div className="container mx-auto px-4 bg-gradient-to-br from-indigo-100 to-purple-100 rounded-xl shadow-2xl p-8 my-10">
      <div className="mb-6 flex flex-wrap justify-between items-center gap-4">
        <div className="flex space-x-2">
          <button
            onClick={handleSelectAllEvents}
            className="bg-indigo-600 text-white px-4 py-2 rounded-l hover:bg-indigo-700 transition-colors duration-200 shadow-md text-sm"
          >
            Seleccionar Todos
          </button>
          <button
            onClick={handleDeselectAllEvents}
            className="bg-gray-600 text-white px-4 py-2 rounded-r hover:bg-gray-700 transition-colors duration-200 shadow-md text-sm"
          >
            Deseleccionar Todos
          </button>
        </div>
        <button
          onClick={handleBatchLinkedInPublish}
          disabled={isLoading || selectedEvents.length === 0}
          className="bg-green-600 text-white px-6 py-2 rounded-full hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 shadow-md transform hover:scale-105 text-sm"
        >
          {isLoading ? 'Programando...' : `Programar ${selectedEvents.length} eventos en LinkedIn`}
        </button>
      </div>
      {error && <p className="text-sm text-red-600 mb-4 bg-red-100 p-3 rounded-lg shadow">{error}</p>}
      <div className="mb-8 bg-white rounded-xl shadow-lg overflow-hidden">
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={normalizedEvents}
          editable={true}
          selectable={true}
          eventClick={handleEventClick}
          eventDrop={handleEventDrop}
          eventResize={handleEventResize}
          eventContent={renderEventContent}
          dateClick={handleDateClick}
          height="auto"
          aspectRatio={1.5}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,dayGridWeek,dayGridDay'
          }}
          initialDate={new Date()}
          eventBackgroundColor="#4f46e5"
          eventBorderColor="#4338ca"
          eventTextColor="#ffffff"
          dayHeaderClassNames="bg-indigo-100 text-indigo-800 uppercase text-xs font-semibold py-2"
          dayCellClassNames="hover:bg-indigo-50 transition-colors duration-200 p-1"
          slotLabelClassNames="text-xs text-gray-500"
          allDayClassNames="text-xs"
        />
      </div>
      {selectedEventContent && (
        <div className="bg-white p-6 rounded-xl shadow-lg mt-6 border-l-4 border-indigo-500">
          <h3 className="text-xl font-bold mb-3 text-indigo-800">Contenido del Post Seleccionado:</h3>
          <p className="text-gray-700 whitespace-pre-wrap bg-indigo-50 p-4 rounded-lg text-sm">{selectedEventContent}</p>
        </div>
      )}
      <button
        onClick={handleSaveCalendar}
        className="mt-6 bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-full shadow-lg transition-all duration-200 transform hover:scale-105 text-sm"
      >
        Guardar Calendario
      </button>
      <Dialog 
        open={isNewEventDialogOpen} 
        onClose={() => setIsNewEventDialogOpen(false)}
        PaperProps={{
          className: 'rounded-xl shadow-xl'
        }}
      >
        <DialogTitle className="bg-indigo-100 text-indigo-800">Crear nuevo evento</DialogTitle>
        <DialogContent className="mt-4">
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Título del evento"
            type="text"
            fullWidth
            variant="outlined"
            value={newEvent.title}
            onChange={(e) => handleNewEventChange('title', e.target.value)}
            className="mb-4"
          />
          <TextField
            margin="dense"
            id="content"
            label="Contenido del post"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={newEvent.content}
            onChange={(e) => handleNewEventChange('content', e.target.value)}
            className="mb-4"
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label="Hora del evento"
              value={newEvent.time}
              onChange={(newTime) => handleNewEventChange('time', newTime)}
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" variant="outlined" />}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions className="p-4">
          <Button onClick={() => setIsNewEventDialogOpen(false)} className="text-gray-600 hover:bg-gray-100">
            Cancelar
          </Button>
          <Button onClick={handleNewEventSubmit} className="bg-indigo-600 text-white hover:bg-indigo-700">
            Crear
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Calendar;